.input.success > input{
    border-color: #21ba45 !important;
    background-color: rgb(234, 247, 234) !important;
    border-width: 1px !important;
    font-weight: bold !important;
}

.input.error > input{
    /* border-color: #21ba45 !important; */
    border-width: 1px !important;
    font-weight: bold !important;
}

.ui.labeled.icon.button>.icon {
    background-color: unset !important;
}

body{
    overflow-x: scroll !important;
}