.background {
  width: 100px !important;
  height: 50px !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: #f9fafb !important;
  color: black !important;
}

.line {
  width: 112px;
  height: 47px;
  border-bottom: 1px solid #ddd;
  transform: translateY(-20px) translateX(5px) rotate(27deg);
  position: absolute;
  z-index: 1;
}

.background>div {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.bottom {
  position: absolute;
  bottom: 3px;
  left: 10px;
}

.top {
  position: absolute;
  top: 3px;
  right: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: center !important;
}